p {
  color: red;
}

.eip {
  width: 100%;
  //min-height: 90vw;
  flex: 1;

  .banner {

  }

}
